import React from "react";
import { CommonButton, StyledForm } from "../../styles/globleStyles";
import { Form, Input, message } from "antd";
import CustomModal from "../CustomModal/CustomModal";
import { useOnBoardInstitutionMutation } from "../../services/createapi";
import { openNotificationWithIcon } from "../../helper/helper";

const ModalForApplyInstitute = ({ visible, onClose }) => {
  const [form] = Form.useForm();

  const [onBoardInstitution, { isLoading }] = useOnBoardInstitutionMutation();

  const onFinish = async (values) => {
    try {
      const response = await onBoardInstitution({
        name: values.InstituteName,
        email: values.email,
      }).unwrap();

      console.log("Success:", response);
      openNotificationWithIcon("success", `${response?.message}`);
      form.resetFields();
      onClose();
    } catch (error) {
      console.error("Error:", error);
      message.error(
        error?.data?.message || "Failed to onboard institute. Please try again."
      );
    }
  };

  return (
    <div>
      <CustomModal
        visible={visible}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        title="Apply as an Institute"
        width="500px"
        paddingTop="1px"
      >
        <div className="child-container">
          <StyledForm
            requiredMark={false}  
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Institute Name"
              name="InstituteName"
              rules={[
                {
                  required: true,
                  message: "Please input Institute Name!",
                },
                {
                  min: 4,
                  message: "Institute Name cannot be less than 4 characters!",
                },
                {
                  max: 32,
                  message: "Institute Name cannot be more than 32 characters!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // Check if value is empty
                    if (!value || value.trim() === "") {
                      return Promise.resolve();
                    }

                    // If value is not empty, check for spaces in between the string
                    if (
                      value.includes(" ") &&
                      value !== getFieldValue("InstituteName")
                    ) {
                      return Promise.reject(
                        new Error("Institute Name cannot contain spaces!")
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Institute Email Id"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <div className="button-container">
                <CommonButton
                  htmlType="submit"
                  fontSize="18px"
                  primary
                  color="#FFF"
                  width="45%"
                >
                  Confirm
                </CommonButton>
                <CommonButton
                  onClick={() => {
                    onClose();
                    form.resetFields();
                  }}
                  width="45%"
                  bordered
                  form={form}
                  fontSize="18px"
                  color="#000"
                >
                  Back
                </CommonButton>
              </div>
            </Form.Item>
          </StyledForm>
        </div>
      </CustomModal>
    </div>
  );
};

export default ModalForApplyInstitute;
