import React, { useState } from "react";
import { CreateButton } from "../../../styles/globleStyles";
import ModalForApplyInstitute from "../../ModalForApplyInstitute/ModalForApplyInstitute";

const HowItWorksSection = () => {
  return (
    <>
      <div className="how-it-works-section">
        <div>
          {/* <div className="heading ">
            <span>How It Works – Simple, Flexible, and Secure!</span>
          </div> */}

          <div className="header">
            <span className="heading">
              How It Works – Simple, Flexible, and Secure!
            </span>
            <div className="paragraph">
              <p style={{ color: "black" }}>
                Access our cutting-edge certification services with two easy
                options tailored to your needs
              </p>
            </div>
          </div>

          {/* List for CRD Tokens and Credit Card Steps */}
          <ol className="steps-list">
            <li>
              <div className="sub-heading">Obtain CRD Tokens:</div>
              <div className="paragraph">
                <p style={{ fontSize: "18px" }}>
                  Seamlessly unlock our platform’s features by acquiring CRD
                  tokens from a qualified decentralized exchange.
                </p>
              </div>
            </li>
            <li>
              <div className="sub-heading">Use a Credit Card:</div>
              <div className="paragraph">
                <p style={{ fontSize: "18px" }}>
                  No tokens? No problem! Pay directly for certification services
                  on our website with a credit card for ultimate convenience.
                </p>
              </div>
            </li>
          </ol>

          {/* Additional information */}
          <div>
            <div className="paragraph">
              <p style={{ color: "black" }}>
                No matter which option you choose, our platform empowers
                institutions to mint and manage certificates efficiently,
                securely, and with confidence.
                <h4 style={{ color: "black" }}>
                  Get started today and take your certification process to the
                  next level!
                </h4>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="sub-heading">
            Choose the Perfect Plan for Your Institution
          </div>
          <div className="paragraph">
            <p>
              Unlock the full potential of our platform with a subscription plan
              tailored to your needs. Whether you're a small institution or a
              large enterprise, our flexible plans provide the right level of
              access and features to help you efficiently mint and manage
              certificates.
              <h4 style={{ color: "black" }}>
                Get started today and elevate your certification process with
                ease, security, and reliability!
              </h4>
            </p>
          </div>
        </div>
        <div>
          <div className="sub-heading">
            Start Minting Certificates Your Way!
          </div>
          <div className="paragraph">
            <p>
              Choose the flexibility that works for you: opt for a subscription
              plan for ongoing access or take advantage of our convenient
              pay-as-you-go option. No matter what you choose, our platform
              makes minting certificates simple, secure, and hassle-free.
              <h4 style={{ color: "black" }}>
                Get started today and issue verifiable certificates with
                confidence!
              </h4>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorksSection;
