import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import HeaderComponent from "./Header";
import FooterComponent from "./Footer";
// import Staking from "../pages/Staking";

const ProtectedRoutes = React.lazy(() => import("../routes/ProtectedRoutes"));

export default function MainSectionWrapper() {
  return (
    <>
    <HeaderComponent/>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/stake" element={<Staking />} /> */}


        <Route path="*" element={<ProtectedRoutes />} />
      </Routes>

      <FooterComponent/>
    </>
  );
}
