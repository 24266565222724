import styled from "styled-components";
import CertificateImage from "../../assets/img/certificate-image.png";

export const MintCertificateSectionStyled = styled.div`
  .content-container {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
    max-height: 929px;
    font-family: Poppins;
    width: 100%;
    gap: 20px;
  }
  .how-it-works-section {
    width: 650px;
    /* height: 761px; */
    display: flex;
    gap: 8px;
    flex-direction: column;
    /* margin-top: 60px; */

    .heading {
      font-size: 40px;
      font-weight: 700;
      line-height: 40.8px;
      text-align: left;
      margin-bottom: 50px;
      line-height: 60px;
    }
    .sub-heading {
      font-size: 20px;
      font-weight: 600;
      line-height: 20.4px;
      text-align: left;
    }

    .paragraph {
      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #494454cc;
      }
    }

    .subheading {
      margin-bottom: 10px;
    }
    ul {
      li {
        font-size: 20px;
        font-weight: 600;
        line-height: 20.4px;
        text-align: left;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #494454cc;
        }
      }
    }
  }

  .cert-btn {
    display: flex;
    justify-content: end;
    margin-right: 29%;
    margin-top: 100px;
    /* margin-top: -54px; */
  }

  .certificate-component {
    /* background-image: url({CertificateImage}); */
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;

    height: fit-content;
    border-radius: 16px;
    margin-top: 150px;

    img {
      max-width: 100%;
      height: auto; /* Maintains aspect ratio */
      border-radius: 16px; /* Optional for rounded corners */
      display: block; /* Removes bottom whitespace */
      margin: 0 auto; /* Centers the image */
    }
  }

  /* @media (max-width: 1024px) {
    .content-container {
      flex-direction: column;
      gap: 20px;
    }

    .how-it-works-section {
      height: 100%;
    }

    .certificate-component {
      width: 100%;
    }
  } */

  @media (max-width: 850px) {
    .content-container {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-height: unset;
      margin-top: 50px;
    }

    .cert-btn {
      justify-content: center;
      align-items: center;
      margin-right: unset;
    }
    .how-it-works-section {
      flex-wrap: wrap;
      max-width: unset;
      margin: 50px;
    }

    .certificate-component {
      width: 650px;
    }
  }

  @media (max-width: 1050px) {
    .content-container {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-height: unset;
      margin-top: 50px;
    }

    .how-it-works-section {
      margin: 50px;
    }
  }

  @media (max-width: 480px) {
    .how-it-works-section {
      flex-wrap: wrap;
      max-width: unset;
      margin: 50px;

      .header {
        text-align: justify;
        .heading {
          font-size: 24px;
          font-weight: 700;
          /* line-height: 40.8px; */
          text-align: center;
          margin-bottom: 50px;
          line-height: 60px;
        }
      }

      .paragraph {
        p {
          font-size: 16px;
          font-weight: 400;
          /* line-height: 24px; */
          text-align: justify;
          color: #494454cc;
        }
      }
    }

    .certificate-component {
      img {
        width: 350px;
      }
    }
  }
`;
