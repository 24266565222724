import styled from "styled-components";

export const SubscriptionSectionStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  .header-container {
    font-family: Poppins;
    text-align: center;
    max-width: 778px;
    margin: 20px;

    .heading {
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      color: #3b4856 80%;
    }
  }
  .subscription-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .select-subscription-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
  }

  .option-card {
    border: 2px solid transparent;
    border-radius: 10px;
    padding: 20px;
    max-width: 465px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    background-color: #f7f7f7;
    box-shadow: rgba(0, 103, 153, 0.4) 0px 6px 45px,
      rgba(0, 0, 0, 0) 0px -12px 36px, rgba(0, 0, 0, 0) 0px 4px 6px,
      rgba(0, 0, 0, 0) 0px 12px 13px, rgba(0, 0, 0, 0) 0px -1px 5px;
    /* max-height: 300px; */
  }

  @media (max-width: 630px) {
    align-items: center;
    justify-content: center;
    .select-subscription-container {
      flex-wrap: wrap;
      justify-content: center;
    }
    .option-card {
      height: 100%;
    }
    .card-content {
      width: auto;
    }
  }

  .card-content {
    position: relative;
    max-width: fit-content;
    top: -22px;
  }
  .card-content-one {
    position: relative;
    max-width: fit-content;
    top: -22px;
  }
  .option-card:hover {
    border-color: #000;
  }

  .option-card.selected {
    border-color: #000;
  }

  .icon-wrapper {
    margin-bottom: 15px;
    text-align: left;
  }

  .option-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .option-description {
    font-size: 0.9rem;
    color: #555;
  }

  .option-card {
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
