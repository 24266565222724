import React from "react";
import { SubscriptionSectionStyled } from "./style";
import { ReactComponent as SubscriptionIcon } from "../../assets/img/subscriptionIcon.svg";
import { ReactComponent as PayAsYouGoIcon } from "../../assets/img/payAsYouGoIcon.svg";

const SubscriptionSection = () => {
  return (
    <SubscriptionSectionStyled>
      <div className="header-container">
        <span className="heading">Mint Certificate</span>
        <p className="description">
          Take your certificates to the next level by minting them
          <b> on the blockchain</b>. Ensure each certificate is secure,
          tamper-proof, and easily verifiable – forever. By leveraging
          blockchain technology, you provide recipients with a
          <b> permanent record </b>
          of their achievements that can’t be altered or lost.
        </p>
      </div>
      <div className="subscription-container">
        <div className="select-subscription-container">
          <div className="option-card">
            <div className="icon-wrapper">
              <PayAsYouGoIcon />
            </div>
            <div className="card-content">
              <span className="option-title">Pay As You Go</span>
              <p className="option-description">
                Our pay-as-you-go service lets you mint certificates on demand
                without any long-term commitment. Pay only for what you need,
                when you need it, while enjoying a simple, secure, and
                cost-effective solution. Perfect for institutions with
                occasional or variable certificate requirements, it’s
                flexibility at its best.
                <h4 style={{ color: "black" }}>
                  Start minting today and pay only for what you use!
                </h4>
              </p>
            </div>
          </div>

          <div className="option-card">
            <div className="icon-wrapper">
              <SubscriptionIcon />
            </div>
            <div className="card-content-one">
              <span className="option-title">Monthly Subscription</span>
              <p className="option-description">
                Our subscription service is perfect for institutions with
                ongoing needs, offering unlimited access to mint certificates at
                a lower cost. Save time and money with predictable pricing while
                enjoying seamless, uninterrupted service. With scalable plans
                and priority support, you’ll have everything you need to
                streamline your certification process.
                <h4 style={{ color: "black" }}>
                  Choose a subscription today and elevate your efficiency!
                </h4>
              </p>
            </div>
          </div>
        </div>
      </div>
    </SubscriptionSectionStyled>
  );
};

export default SubscriptionSection;
