import React, { useState } from "react";
import { StyledHeroSection } from "./styles";
import { CommonButton, CreateButton } from "../../styles/globleStyles";
import useStakingProgram from "../../hook/useStakingProgram";
import CustomModal from "../Modal";
import { useWallet } from "@solana/wallet-adapter-react";
import { Tooltip } from "antd";
import useFetchStakeAccountData from "../../hook/useFetchStakeAccountData";
import ModalForApplyInstitute from "../ModalForApplyInstitute/ModalForApplyInstitute";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { deposit } = useStakingProgram();
  const { publicKey, connected } = useWallet();
  const { fetchData } = useFetchStakeAccountData();
  console.log("publicKey,connected", publicKey, connected);

  const navigate = useNavigate();

  const handleStake = async (amount) => {
    console.log("Staking amount:", amount);
  };

  const handleDeposit = async (amount) => {
    try {
      const tx = await deposit(amount);
      console.log("Deposit transaction:", tx);
    } catch (error) {
      console.error("Failed to deposit:", error);
    }
  };

  const handleOpenStakeModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleStakeAndDeposit = async (amount) => {
    await handleStake(amount);
    await handleDeposit(amount);
  };

  return (
    <StyledHeroSection>
      <div className="content-container">
        <div className="heading">
          Welcome to the Institute
          <p>Certification Portal</p>
        </div>
        <div className="paragraph">
          <p>
            Welcome to our cutting-edge Certification Portal! Here, institutes
            can effortlessly mint and manage their certificates using our robust
            blockchain technology. Whether you’re a large educational
            institution or a specialized training provider, our platform
            provides a seamless experience for issuing verified and tamper-proof
            certificates to your students.
          </p>
        </div>
        <div className="btn-container">
          <CreateButton onClick={handleOpenStakeModal} style={{ width: 260 }}>
            Start your Digitalization
          </CreateButton>
          {/* <CommonButton
            primary
            width="260px"
            style={{ fontSize: 18 }}
            onClick={() => navigate("/stake")}
          >
            Stake your CRD
          </CommonButton> */}
        </div>
        {/* <div className="btn">
          {!connected ? (
            <Tooltip placement="bottom" title={"Please connect your wallet !"}>
              <CreateButton
                disabled={!connected}
                onClick={handleOpenStakeModal}
              >
                Stake your CRD Tokens
              </CreateButton>
            </Tooltip>
          ) : (
            <CreateButton onClick={handleOpenStakeModal}>
              Stake your CRD Tokens
            </CreateButton>
          )}
        </div> */}
        {/* <CustomModal
          visible={modalVisible}
          onCancel={handleCancel}
          onStakeAndDeposit={handleStakeAndDeposit}
          title="Stake your CRD Token"
          action="stake"
        /> */}
        <ModalForApplyInstitute
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
      </div>
    </StyledHeroSection>
  );
};

export default HeroSection;
